<template>
  <div class="Earningorder">
    <van-nav-bar
      title="提现明细"
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="!nodata">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="nodata ? '' : '没有更多了'"
        :immediate-check="false"
        @load="onLoad"
      >
        <div class="order_list" v-for="(item, index) in list" :key="index">
          <van-cell :border="false" center class="userinfo">
            <template #icon>
              <div>
                <van-image
                  width="20px"
                  height="20px"
                  lazy-load
                  round
                  fit="cover"
                  style="display: block;margin-right: 5px;"
                  :src="item.avatar ? item.avatar : 'https://dk.midbest.cn/img/head.ede6594c.png'"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
            </template>
            <template #title>
              <div class="van-ellipsis">{{item.nickname ? item.nickname : '未设置昵称'}}</div>
            </template>
            <template #right-icon>
              <span style="margin-right: 10px;">{{item.ordersn}}</span>
              <van-button size="mini" @click.stop="copay(item.ordersn)">复制</van-button>
            </template>
          </van-cell>
          <van-card
            v-for="(val, key) in item.order_goods"
            :key="key"
            :num="val.total"
            :price="val.realprice"
            :desc="val.optionname"
            :title="val.goodstitle"
            :thumb="val.goodsthumb"
            lazy-load
          >
          </van-card>
          <van-cell center>
            <template #icon>
              <span>{{item.status_desc}}</span>
            </template>
            <template #title>
              <div class="order_total">
                <span>实付：&yen;{{item.money_payed}}</span>
              </div>
            </template>
          </van-cell>
          <van-cell center>
            <template #icon>
              <div class="red">
                {{item.merchfakeapply_desc}}
              </div>
            </template>
            <template #title>
              <div class="order_total">
                <span class="iconme" v-if="item.ydzg && item.money_chajia_real > 0">{{item.ydzg}}</span>
                <span class="iconme">C：&yen;{{item.money_chajia_real}}</span>
              </div>
            </template>
          </van-cell>
        </div>
      </van-list>
    </div>
    <div v-if="nodata">
      <van-empty description="暂无订单" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Earningorder",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      show: false, // 错误提示显示
      list: [],
      id: 0, // 提现id
      size: 20,
      page: 1,
      nodata: false,
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.init();
  },
  activated() {
    console.log('ddd');
  },
  methods: {
    init() {
      this.onLoad();
    },
    // 获取提现明细
    onLoad() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .get(that.$store.state.domain + "shopsmobile/order/get_bill_detail?bill_id="+that.id+"&page_id="+that.page)
        .then(res => {
          that.loading = false;
          that.nodata = false;
          if (res.data.code == 100000) {
            if (that.page == 1) {
              that.list = res.data.data;
            }else{
              res.data.data.forEach(item => {
                that.list.push(item);
              })
            }
            that.page++;
            if (res.data.data.length < 20) {
              that.finished = true;
            }
          }else{
            that.finished = true;
            if (that.page == 1) {
              that.nodata = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          that.finished = true;
          if (that.page == 1) {
            that.nodata = true;
          }
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 复制信息
    copay(ordersn) {
      let _that = null;
      _that = this;
      _that.$copyText(ordersn)
      .then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      )
    },
  }
}
</script>
<style lang="less">
.Earningorder {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  padding-bottom: 60px;
  background-color: #f0f0f0;
  // 订单列表
  .order_list {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    margin: 10px 0;
    &:last-of-type {
      margin-bottom: 0;
    }
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: left;
        overflow: hidden;
        .order_total {
          width: 100%;
          text-align: right;
          .iconme {
            margin-left: 10px;
            color: #ee0a24;
            border: 1px solid #ee0a24;
            border-radius: 2px;
            padding: 1px 5px;
            font-size: 10px;
          }
        }
      }
    }
    .userinfo .van-cell__title {
      margin-right: 5px;
    }
    .van-card {
      background-color: #fff;
      text-align: left;
      margin-top: 0;
    }
    .red {
      color: #ee0a24;
    }
  }
}
</style>